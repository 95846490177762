<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="导入"
      @ok="handleSubmit"
    >
      <a-form
        class="simiot-compact-form"
        :form="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 15 }"
        @submit="handleSubmit"
      >
        <a-form-item label="卡清单" required>
          <upload-custom-file
            accept=".xls,.xlsx"
            hint="支持excel格式，20MB以内"
            :max-size="20*1024*1024"
            @change="handleUploadChange"
            v-decorator="['attachment', {
              rules: [{ validator: checkFileList }],
            }]"
          />
        </a-form-item>

        <a-form-item label="模版">
          <div><a href="/static/import_template.xlsx" target="_blank">点击下载导入模版</a><font color="red"> (ICCID必填)</font></div>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { importSimCards } from '@/api/oc_sim_card'
import UploadCustomFile from '@/components/Upload/CustomFile'

export default {
  name: 'UploadSimCard',
  components: { UploadCustomFile },
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      fileList: [],
      form: this.$form.createForm(this, { name: 'import_sim_card' }),
      submitting: false
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
  },
  methods: {
    handleUploadChange(fileList) {
      this.fileList = fileList
    },

    checkFileList(rule, value, callback) {
      if (!this.fileList[0] || this.fileList[0].status === 'error') {
        // eslint-disable-next-line standard/no-callback-literal
        callback('请上传文件')
        return
      }

      // eslint-disable-next-line standard/no-callback-literal
      callback()
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      if (this.$lodash.find(this.fileList, { status: 'uploading' })) {
        this.$warning({
          title: '文件尚未上传完成',
          content: ''
        })
        return
      }

      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          const data = {
            ...values,
            attachment: this.fileList[0].response
          }
          importSimCards(data).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
              this.$success({
                title: '提交成功',
                content: (
                  <div>
                    <p>操作成功，具体操作结果请至 【系统查询】-【批量操作查询】 查看</p>
                  </div>
                )
              })
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>
